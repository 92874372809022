<template>
  <div class="product my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="floor main-content">
          <div class="floor-style-2">
		<div class="body-wrap">
			<ul class="goods-list">
				<li v-for="(item, index) in list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
					<div class="img-wrap"><img alt="商品图片" :src="$baseRemote + item.sku_image" @error="imageError(index)" /></div>
					<h3>{{ item.goods_name }}</h3>
					<!-- <p class="desc">{{ item.introduction }}</p> -->
					<p class="price">
						<span style="color:black;font-size:smaller">市场指导价:</span><span class="num">¥{{Math.floor(item.price / item.goods_stock_alarm)}}/{{ item.unit=='箱'?'瓶':item.unit }}</span><br/>
						<span style="color:black;font-size:smaller">会员价:</span><span class="num">¥{{ showVipPrice(item) }}/{{ item.unit=='箱'?'瓶':item.unit }}</span>
						<!-- <block v-if="item.market_price_show">
							<del>{{ item.market_price }}元</del>
						</block> -->
					</p>
				</li>
			</ul>
		</div>

	</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import http from "@/utils/http"
  import { mapGetters } from 'vuex';
  import index from "../index/_index/index.js"
  export default {
    name: "product",
    components: {
		...mapGetters(['defaultGoodsImage'])
    },
    mixins: [index],
    mounted() {
      this.getlist()
    },
	data() {
		return {
        list: []
    };
	},
    methods: {
      getlist(){
        http({
          url: "/api/goodssku/page",
          data: { page: 1, page_size: 50}
        }).then((res) => {
          this.list = res.data.list.filter(item => !(item.goods_id === 14 || item.goods_id === 15 || item.goods_id === 16));
        })
      },
      goSku(skuId) {
        this.$router.pushToTab('/sku-' + skuId);
      },
      imageError(index) {
        this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
      },
	  // VIP价格
	  showVipPrice(data) {
      let discount_method = data.discount_method || 'fixed_price';
      let member_price = data.member_price || '{}';
	  let price = data.price || 0;
      let goods_stock_alarm = data.goods_stock_alarm || 1;

      if (!data.member_price) {
        return Math.floor(price / goods_stock_alarm);
      }

      let fixed_price = JSON.parse(member_price) || {};
      fixed_price = fixed_price[discount_method]['3'] || fixed_price[discount_method]['4'];
      return Math.floor(fixed_price / goods_stock_alarm);
    }
    }
  }
</script>
<style lang="scss" scoped>
  .floor {
    .floor_item {
      margin-top: 10px;
    }
    padding-bottom: 20px;
  }
  .product {
    background-color: #f9f9f9
  }
  .head-wrap {
    text-align: left;
  }
  
.floor-style-2 {
	.head-wrap {
		text-align: center;
		h2 {
			line-height: 30px;
			color: #333;
			padding: 10px;
			font-size: 22px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		p {
			color: #b0b0b0;
			padding: 0 10px;
			font-size: 14px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 20px;
		}
	}
	.body-wrap {
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 23%;
				margin-left: 15px;
				margin-bottom: 15px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				&:nth-child(4n + 1) {
					margin-left: 0;
				}
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.img-wrap {
					// width: 160px;
					// height: 160px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 90%;
						max-height: 90%;
					}
				}
				h3 {
					font-size: 14px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 30px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					margin: 0 10px 14px;
					text-align: center;
					color: #eb0004;
					font-size: large;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}
</style>
